import { useState } from "react"
import styled from "styled-components"

import { useConnectOauth } from "src/data/integrations/queries/integrationAuthQueries"
import {
  OwnerType,
  TIntegration,
} from "src/data/integrations/types/integrationTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { ConfirmDialog } from "src/ui/Dialog/ConfirmDialog"

export function UpdateAirbnbIntegrationDialog({
  integration,
  onClose,
  accountId,
}: {
  integration: TIntegration
  onClose: () => void
  accountId: string
}) {
  const { t, langKeys } = useTranslate()

  const { orgId } = useOrganization()
  const connectOauth = useConnectOauth({ ownerType: OwnerType.ORGANIZATION })

  const [redirecting, setRedirecting] = useState(false)

  const redirectUrl =
    window.location.origin +
    Routes.OrgIntegration.location(integration.integration_identifier).pathname

  function handleOauthFlow() {
    setRedirecting(true)
    connectOauth.mutate(
      {
        orgId,
        redirectUrl: redirectUrl,
        integration: integration.integration_identifier,
        reauthorizeAccountId: accountId,
      },
      {
        onError: (e) => {
          setRedirecting(false)
        },
      }
    )
  }

  return (
    <ConfirmDialog
      title={t(langKeys.integration_airbnb_update_dialog_header)}
      confirmLabel={t(langKeys.update)}
      cancelLabel={t(langKeys.skip_for_now)}
      open={true}
      onClose={onClose}
      onConfirm={handleOauthFlow}
      loading={redirecting}
    >
      <DialogBody>
        {t(langKeys.integration_airbnb_update_dialog_body)}
      </DialogBody>
    </ConfirmDialog>
  )
}

const DialogBody = styled.div`
  white-space: pre-line;
`
