import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { AxiosError } from "axios"
import { useSnackbar } from "notistack"

import { API_DEFAULT } from "src/constants/minutApi"
import {
  integrationKeys,
  useIntegrationCache,
} from "src/data/integrations/queries/integrationQueryCache"
import {
  OwnerType,
  TClientCredentialsResponse,
  TIntegrationId,
  TntegrationAuthCredentialResponse,
} from "src/data/integrations/types/integrationTypes"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

export function useFetchActiveCredentials({
  ownerId,
  integration,
  ownerType,
  filters,
  options,
}: {
  ownerId: string
  ownerType: string
  integration: TIntegrationId
  filters: { limit: number; offset: number }
  options?: UseQueryOptions<
    TntegrationAuthCredentialResponse,
    AxiosError,
    TntegrationAuthCredentialResponse,
    ReturnType<typeof integrationKeys.multiAccountCredentials>
  >
}) {
  // TODO: PRD-1620 remove hardcoded data when we have backend endpoints
  async function fetchAvailablePlansForUser({
    ownerId,
    ownerType,
    integration,
    filters,
  }: {
    ownerId: string
    ownerType: string
    integration: TIntegrationId
    filters?: { limit: number; offset: number }
  }) {
    const response =
      await minutApiHttpClient.get<TntegrationAuthCredentialResponse>(
        `${API_DEFAULT}/integrations/auth/credentials`,
        {
          params: {
            owner_id: ownerId,
            owner_type: ownerType,
            integration_identifier: integration,
            limit: filters?.limit,
            offset: filters?.offset,
          },
        }
      )

    return response.data
  }

  return useQuery(
    integrationKeys.multiAccountCredentials({
      orgId: ownerId,
      integration,
      filters,
    }),
    () =>
      fetchAvailablePlansForUser({ ownerId, ownerType, integration, filters }),
    options
  )
}

export function useDeleteCredentialMutation() {
  const { invalidateMultiaccountCredentials } = useIntegrationCache()
  async function deleteCredential({
    ownerId,
    ownerType,
    integration,
    integrationAccountId,
  }: {
    ownerId: string
    ownerType: OwnerType
    integration: TIntegrationId
    integrationAccountId: string
  }) {
    await minutApiHttpClient.delete(
      `${API_DEFAULT}/integrations/auth/credentials/${integrationAccountId}`,
      {
        params: {
          owner_id: ownerId,
          owner_type: ownerType,
          integration_identifier: integration,
        },
      }
    )
  }

  return useMutation(deleteCredential, {
    onSuccess: (_data, vars) => {
      invalidateMultiaccountCredentials({
        orgId: vars.ownerId,
        integration: vars.integration,
      })
    },
  })
}

export function useIntegrationSecretMutation({
  ownerType = OwnerType.ORGANIZATION,
}: {
  ownerType: OwnerType
}) {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  async function setSecret({
    orgId,
    integration,
    secret,
    reauthorize,
  }: {
    orgId: string
    integration: string
    secret: string
    reauthorize?: boolean
  }) {
    const data = {
      owner_id: orgId,
      owner_type: ownerType,
      integration_id: integration,
      secret: secret,
      reauthorize,
    }
    const response = await minutApiHttpClient.post(
      `${API_DEFAULT}/integrations/auth/secret`,
      data
    )
    return response.data
  }

  return useMutation({
    mutationFn: setSecret,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
    onError: (err: AxiosError<any>) => {
      const message = err.response?.data.message || "Something went wrong"
      enqueueSnackbar(message, {
        variant: "error",
      })
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(integrationKeys.all())
      // TODO WEB-xxx: Use result for updating cache, and invalidate cache based on org
    },
  })
}

export function usePostIntegrationMultiSecret({
  ownerType = OwnerType.ORGANIZATION,
}: {
  ownerType: OwnerType
}) {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  async function setSecret({
    orgId,
    integration,
    secrets,
    reauthorize,
  }: {
    orgId: string
    integration: string
    secrets: { id: string; secret: string }[]
    reauthorize?: boolean
  }) {
    const data = {
      owner_id: orgId,
      owner_type: ownerType,
      integration_id: integration,
      secrets,
      reauthorize,
    }
    const response = await minutApiHttpClient.post(
      `${API_DEFAULT}/integrations/auth/multisecret`,
      data
    )
    return response.data
  }

  return useMutation({
    mutationFn: setSecret,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
    onError: (err: AxiosError<any>) => {
      const message = err.response?.data.message || "Something went wrong"
      enqueueSnackbar(message, {
        variant: "error",
      })
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(integrationKeys.all())
      // TODO WEB-xxx: Use result for updating cache, and invalidate cache based on org
    },
  })
}

export function useClientCredentialsMutation(props: { ownerType: OwnerType }) {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  /** Set client credentials
   * https://api.staging.minut.com/latest/docs/internal#tag/Integrations/paths/~1integrations~1auth~1oauth2~1client_credentials/post
   */
  async function setClientCredentials({
    orgId,
    integration,
    clientId,
    clientSecret,
    reauthorize,
  }: {
    orgId: string
    integration: string
    clientSecret: string
    clientId: string
    reauthorize?: boolean
  }) {
    const data = {
      owner_id: orgId,
      owner_type: props.ownerType,
      integration_id: integration,
      integration_identifier: integration,
      client_id: clientId,
      client_secret: clientSecret,
      reauthorize,
    }
    const response = await minutApiHttpClient.post<TClientCredentialsResponse>(
      `${API_DEFAULT}/integrations/auth/oauth2/client_credentials`,
      data
    )
    return response.data
  }

  return useMutation({
    mutationFn: setClientCredentials,
    onError: (err: AxiosError<{ message?: string }>) => {
      const message = err.response?.data.message || "Something went wrong"
      enqueueSnackbar(message, {
        variant: "error",
      })
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(integrationKeys.all())
      // TODO WEB-xxx: Use result for updating cache, and invalidate cache based on org
    },
  })
}

export function useConnectOauth({ ownerType }: { ownerType: OwnerType }) {
  const { enqueueSnackbar } = useSnackbar()

  async function oAuthConnect({
    orgId,
    redirectUrl,
    integration,
    reauthorizeAccountId,
    url = "/integrations/auth/oauth2/authorization",
  }: {
    orgId: string
    redirectUrl: string
    integration: string
    reauthorizeAccountId?: string
    url?: string
  }) {
    const encodedRedirectUrl = encodeURIComponent(redirectUrl)

    const reauthorizeParam = !!reauthorizeAccountId
      ? `&reauthorize=true&account_id=${reauthorizeAccountId}`
      : ""
    const { data } = await minutApiHttpClient.get<{
      authorization_url: string
    }>(
      // We cannot use the axios params builder here because it either
      // double-encodes or doesn't encode query params at all:
      `${API_DEFAULT}${url}` +
        `?owner_id=${orgId}` +
        `&owner_type=${ownerType}` +
        `&integration_identifier=${integration}` +
        `&app_redirect_uri=${encodedRedirectUrl}` +
        reauthorizeParam
    )
    const authUrl: string = data.authorization_url
    return authUrl
  }

  return useMutation(oAuthConnect, {
    onSuccess: (url) => {
      window.location.assign(url)
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
    onError: (err: AxiosError<any>) => {
      const data = err.response?.data
      const message = data
        ? `${data.message}: ${data.context}`
        : "Something went wrong"
      enqueueSnackbar(message, {
        variant: "error",
      })
    },
  })
}
