import styled from "styled-components"

import { amountOfConnections } from "src/data/integrations/logic/integrations"
import { TIntegration } from "src/data/integrations/types/integrationTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MBanner } from "src/ui/MBanner/MBanner"
import { spacing } from "src/ui/spacing"

export function GotoMultiAccountSettingsBanner({
  integration,
}: {
  integration: TIntegration
}) {
  const { t, langKeys } = useTranslate()

  const { navigate } = useRouter()

  const hasMultiAccountSupport = integration.auth?.multi_account_support
  const hasOnlyOneAccountLinked = amountOfConnections(integration) === 1

  const showBanner = hasMultiAccountSupport && hasOnlyOneAccountLinked

  if (!showBanner) return null

  return (
    <ContentBox>
      <MBanner type="info" fullWidth>
        {t(langKeys.integrations_multiaccount_banner_title, {
          name: integration.name,
        })}{" "}
        <InternalLink
          to={Routes.OrgIntegrationSettingsTab.location(
            integration.integration_identifier
          )}
          color="unset"
        >
          {t(langKeys.integrations_multiaccount_banner_link)}
        </InternalLink>
      </MBanner>
    </ContentBox>
  )
}

const ContentBox = styled.div`
  max-width: 80ch;
  margin-right: ${spacing.M};
`
